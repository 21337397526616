import { lazy } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { ReactQueryProvider } from '@/components/ReactQueryProvider';
import { APP_ENV } from '@/constant';
import { AuthProtector, AuthProvider } from '@/hooks/useAuth';
import { useAutoChangeTheme } from '@/hooks/useTheme';
import TabLayout from '@/layouts/TabLayout';
import NotFound from '@/pages/NotFound';

const IS_PRODUCTION = APP_ENV === 'production';
const DEBUG_PAGE_PATH = IS_PRODUCTION ? 'harmo-debug-114514' : 'debug';

const GlobalToaster = lazy(() => import('@/components/GlobalToaster'));

const AuthSignIn = lazy(() => import('@/pages/auth/SignIn'));
const AuthOAuth2Callback = lazy(() => import('@/pages/auth/OAuth2Callback'));
const AuthSignOut = lazy(() => import('@/pages/auth/SignOut'));
const AuthCreateAccount = lazy(() => import('@/pages/auth/CreateAccount'));

const OAuthTest = lazy(() => import('@/pages/oauth/Test'));
const OAuthClients = lazy(() => import('@/pages/oauth/Clients'));
const OAuthAuthScreen = lazy(() => import('@/pages/oauth/AuthScreen'));
const OAuthChooseAccount = lazy(() => import('@/pages/oauth/ChooseAccount'));
const OAuthComplete = lazy(() => import('@/pages/oauth/Complete'));

const EmapFindByName = lazy(() => import('@/pages/emap/FindByName'));
const EmapFindByArea = lazy(() => import('@/pages/emap/FindByArea'));
const EmapFindFromMap = lazy(() => import('@/pages/emap/FindFromMap'));

const JanCode = lazy(() => import('@/pages/jan-code'));

const ProjectsList = lazy(() => import('@/pages/projects/ProjectsList'));
const ProjectsProjectDetail = lazy(() => import('@/pages/projects/ProjectDetail'));
const ProjectsQrCodeScanner = lazy(() => import('@/pages/projects/QrCodeScanner'));
const ProjectsCreateProject = lazy(() => import('@/pages/projects/CreateProject'));

const GuidanceHistory = lazy(() => import('@/pages/guidance/GuidanceHistory'));
const GuidanceDetail = lazy(() => import('@/pages/guidance/GuidanceDetail'));
const GuidanceNew = lazy(() => import('@/pages/guidance/GuidanceNew'));
const GuidanceConfirm = lazy(() => import('@/pages/guidance/GuidanceConfirm'));
const GuidanceNewPrescription = lazy(() => import('@/pages/guidance/GuidanceNewPrescription'));
const GuidanceConfirmPrescription = lazy(() => import('@/pages/guidance/GuidanceConfirmPrescription'));
const GuidanceFindFacility = lazy(() => import('@/pages/guidance/GuidanceFindFacility'));
const GuidanceDetailPrescription = lazy(() => import('@/pages/guidance/GuidanceDetailPrescription'));

const MynapoLinkMynaData = lazy(() => import('@/pages/mynapo/LinkMynaData'));
const MynapoSpecialHealthCheckUpData = lazy(() => import('@/pages/mynapo/SpecialHealthCheckUpData'));

const EScanQrResult = lazy(() => import('@/pages/escan/EScanQrResult'));
const PaymentCreditCardInfo = lazy(() => import('@/pages/payment/CreditCardInfo'));

const DebugPage = lazy(() => import('@/pages/debug'));

function App() {
  useAutoChangeTheme();

  return (
    <ReactQueryProvider>
      <GlobalToaster />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<TabLayout />}>
              {/* ランディングページ、現在はOAuth連携先一覧画面に遷移 */}
              <Route index element={<Navigate replace to="/oauth/clients" />} />

              {/* 認証画面 */}
              <Route path="auth">
                <Route path="sign-in" element={<AuthSignIn />} />
                <Route path="oauth2-callback" element={<AuthOAuth2Callback />} />
                <Route path="sign-out" element={<AuthSignOut />} />
                <Route path="create-account" element={<AuthCreateAccount />} />
              </Route>

              {/* OAuth連携 */}
              <Route
                path="oauth"
                element={
                  <AuthProtector>
                    <Outlet />
                  </AuthProtector>
                }
              >
                <Route path="clients" element={<OAuthClients />} />
                <Route path="test" element={<OAuthTest />} />
                <Route path="auth" element={<OAuthAuthScreen />} />
                <Route path="choose-account" element={<OAuthChooseAccount />} />
                <Route path="complete" element={<OAuthComplete />} />
              </Route>

              {/* eMap電子地図 */}
              <Route path="emap">
                <Route index element={<EmapFindByName />} />
                <Route path="find-by-area" element={<EmapFindByArea />} />
                <Route path="find-from-map" element={<EmapFindFromMap />} />
              </Route>

              {/* プロジェクト機能 */}
              <Route
                path="jan-code"
                element={
                  <AuthProtector>
                    <JanCode />
                  </AuthProtector>
                }
              />

              {/* プロジェクト機能 */}
              <Route
                path="projects"
                element={
                  <AuthProtector>
                    <Outlet />
                  </AuthProtector>
                }
              >
                <Route index element={<ProjectsList />} />
                <Route path="qr-code-scanner" element={<ProjectsQrCodeScanner />} />
                <Route path="create" element={<ProjectsCreateProject />} />
                <Route path=":projectId" element={<ProjectsProjectDetail />} />
              </Route>
              <Route
                path="guidance"
                element={
                  <AuthProtector>
                    <Outlet />
                  </AuthProtector>
                }
              >
                <Route index element={<GuidanceHistory />} />
                <Route path="find-facility" element={<GuidanceFindFacility />} />
                <Route path=":dispensingOrderId" element={<GuidanceDetail />} />
                <Route path="new" element={<GuidanceNew />} />
                <Route path="confirm" element={<GuidanceConfirm />} />
                <Route path="new-prescription" element={<GuidanceNewPrescription />} />
                <Route path="confirm-prescription" element={<GuidanceConfirmPrescription />} />
                <Route path="prescription/:dispensingOrderId" element={<GuidanceDetailPrescription />} />
              </Route>

              {/* マイナポ連携 */}
              <Route
                path="mynapo"
                element={
                  <AuthProtector>
                    <Outlet />
                  </AuthProtector>
                }
              >
                <Route index element={<Navigate replace to="link-myna-data" />} />
                <Route path="link-myna-data" element={<MynapoLinkMynaData />} />
                <Route path="special-health-check-up-data" element={<MynapoSpecialHealthCheckUpData />} />
              </Route>
            </Route>

            {/* e薬scan */}
            <Route
              path="e-scan"
              element={
                <AuthProtector>
                  <Outlet />
                </AuthProtector>
              }
            >
              <Route path="qr-result" element={<EScanQrResult />} />
            </Route>

            {/* 支払い情報 */}
            <Route
              path="payment"
              element={
                <AuthProtector>
                  <Outlet />
                </AuthProtector>
              }
            >
              <Route path="credit-card" element={<PaymentCreditCardInfo />} />
            </Route>

            {/* テスト画面 */}
            <Route path={DEBUG_PAGE_PATH} element={<DebugPage />} />

            {/* 404画面 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </ReactQueryProvider>
  );
}

export default App;
