import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Loading } from '@/components/Loading';
import { APP_ENV } from '@/constant';

const IS_PRODUCTION = APP_ENV === 'production';

function TabLayout() {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      {
        // Cloudflare Web Analytics
        IS_PRODUCTION && (
          <script
            defer
            src="https://static.cloudflareinsights.com/beacon.min.js"
            data-cf-beacon='{"token": "c16dd968a1894810a8c1dec45a07b7fb"}'
          ></script>
        )
      }
    </>
  );
}

export default TabLayout;
