import { Button } from 'react-daisyui';
import { IoMdCheckmarkCircle, IoMdCloseCircle, IoMdInformationCircle, IoMdWarning } from 'react-icons/io';

import { useShowDownloadHarmoToast } from '@/utils/showDownloadHarmoToast';

type Props = {
  message: React.ReactNode;
  buttonTitle?: React.ReactNode;
  secondButtonTitle?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onSecondClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  iconType?: 'warning' | 'info' | 'success' | 'error';
};

export function MessageScreen(props: Props) {
  const { message, buttonTitle, secondButtonTitle, onClick, onSecondClick, iconType = 'warning' } = props;

  const icon = {
    warning: <IoMdWarning className="size-12 mx-auto mb-2 text-warning" />,
    info: <IoMdInformationCircle className="size-12 mx-auto mb-2 text-info" />,
    success: <IoMdCheckmarkCircle className="size-12 mx-auto mb-2 text-success" />,
    error: <IoMdCloseCircle className="size-12 mx-auto mb-2 text-error" />,
  }[iconType];

  useShowDownloadHarmoToast();

  return (
    <div className="flex flex-col justify-center h-full mx-4 my-auto">
      <div className="flex flex-col gap-4 mb-40">
        <div className="p-4 py-8 text-base leading-loose text-center whitespace-pre-line bg-base-100 rounded-3xl">
          {icon}
          <p>{message}</p>
        </div>
        {!!buttonTitle && !!onClick && (
          <Button
            type="button"
            color="primary"
            fullWidth
            className="text-base font-bold rounded-full"
            onClick={onClick}
          >
            {buttonTitle}
          </Button>
        )}
        {!!secondButtonTitle && !!onSecondClick && (
          <Button
            type="button"
            color="secondary"
            fullWidth
            className="text-base font-bold rounded-full"
            onClick={onSecondClick}
          >
            {secondButtonTitle}
          </Button>
        )}
      </div>
    </div>
  );
}
